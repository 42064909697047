@use "sass:map";

// snack-bar
.snack-bar {
  height: 5.6rem;
  width: 35rem;
  padding: 0 !important;
  margin-top: 0 !important;
  .mat-mdc-simple-snackbar {
    height: 100%;
  }
  .mat-mdc-simple-snackbar-action {
    color: white;
    margin: 2rem;
    button {
      padding: 0;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
  .mat-mdc-simple-snackbar > span {
    height: 3.2rem;
    border-right: 1px solid white;
    padding: 1.2rem 1rem 1.2rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    line-height: 1.6rem;
  }
}
.mat-mdc-simple-snackbar,
.mat-mdc-simple-snack-bar {
  .mat-simple-snackbar-action,
  .mat-mdc-snack-bar-action {
    color: white !important;
  }
}
.no-action {
  .mat-mdc-simple-snackbar > span {
    border: 0 !important;
  }
}
.default-style {
  .mat-mdc-simple-snack-bar {
    .mat-mdc-snack-bar-label {
      color: $color-grey-800 !important;
    }
  }
  .mat-mdc-simple-snackbar > span {
    color: $color-grey-800 !important;
  }
  .mat-mdc-simple-snackbar-action {
    color: $color-primary-600 !important;
  }
}
// progress-bar
mat-progress-bar {
  border-radius: 2.5rem;
  height: 0.5rem;
}

// tree-list
mat-tree {
  overflow: auto;
  mat-tree-node,
  mat-nested-tree-node {
    min-height: 3rem !important;
  }
  mat-tree-node > a,
  mat-nested-tree-node li a {
    margin-top: 0.2rem;
  }
  background-color: transparent !important;
  li {
    width: 100%;
  }
  ul,
  li {
    list-style-type: none;
  }
  .invisible {
    display: none;
  }
}
.tree-dummies-container {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: $color-grey-50;
  &.loaded {
    animation: fadeOut 0.5s linear forwards;
  }
}

// spinner
.spinner {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &.invisible {
    display: none;
  }
}

// pdf
ngx-extended-pdf-viewer {
  pdf-toolbar .toolbar #toolbarContainer {
    box-shadow: none !important;
    background-image: none !important;
    background-color: white !important;

    display: flex;
    justify-content: space-between;
    align-items: center;

    // padding: 0 2rem;

    .pdf-actions {
      display: flex;
      align-items: center;

      a {
        margin: 0 2.5px;
      }
    }
  }
}

.hiddenCanvasElement {
  display: none;
}

// Accordion
mat-expansion-panel.mat-expansion-panel {
  border-top: 1px solid $color-grey-200;

  mat-expansion-panel-header.mat-expansion-panel-header {
    padding: 0;
    height: 5rem;
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.6rem;
  }

  .mat-expansion-panel-body {
    margin: 0;
    padding: 0;
  }

  &:not(.mat-expansion-cards-panel):not(.default-expansion-panel) {
    border-radius: 0 !important;
    box-shadow: none !important;

    mat-expansion-panel-header.mat-expansion-panel-header {
      .mat-expansion-indicator::after {
        color: $color-primary-900;
        transform: rotate(-45deg);
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator::after {
        transform: rotate(225deg) !important;
      }
    }
  }
}

// Login
.oauth {
  max-width: 72rem;
  .oauth_providers {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    &.multi {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    a {
      display: flex;
      align-items: center;
      border: 1.5px solid $color-grey-200;
      border-radius: 3px;
      height: 4rem;
      mat-icon {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
      }
      span {
        border-left: 1px solid $color-grey-200;
        height: 3.8rem;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      &:hover,
      &:active,
      &.active {
        border-color: $color-primary-600;
        color: black;
      }
    }
  }
}

// Datepicker
.mat-calendar-body-selected {
  color: white !important;
}

.hr-text {
  border: 0;
  font-size: 14px;
  font-weight: bold;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: center;

  &::before {
    content: "";
    background: linear-gradient(to right, transparent, map.get($grey-palette, 500), transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &::after {
    background-color: white;
    color: map.get($grey-palette, 700);
    content: attr(text-content);
    line-height: 1.5em;
    padding: 0 7px;
    position: relative;
  }
}

// Mat-list
.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-right: 8px !important;
}

.mdc-list-item__content {
  .mdc-list-item__primary-text {
    font-size: 1.3rem !important;
  }
}
