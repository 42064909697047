@use "sass:map";

.p-progressbar {
  border: $progressBarBorder;
  height: $progressBarHeight;
  background: $progressBarBg;
  border-radius: $borderRadius;

  &.thin {
    height: $progressBarThinHeight;
  }

  .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: $progressBarValueBg;
  }
}

.label-zero-active .p-progressbar-determinate .p-progressbar-value {
  width: 100% !important;
  background-color: transparent !important;
  position: relative;
}

.p-progressbar-label {
  color: $progressBarValueTextColor;
  line-height: $progressBarHeight;
}

.label-zero-active .progress-label {
  color: $color-black !important;
}

.grey-progress .p-progressbar-value {
  background: map.get($grey-palette, 700);
}

.blue-progress .p-progressbar-value {
  background: map.get($info-palette, 800);
}

.green-progress .p-progressbar-value {
  background: map.get($success-palette, 700);
}
