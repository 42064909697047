.tip {
  display: flex;
  flex-direction: row;
  .tip-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    margin-right: 2rem;
  }
  .tip-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .tip-text-none {
    padding-left: 0 !important;
  }
  $colors: (
    success: $color-success,
    error: $color-error,
    warning: $color-warning,
    dark: $color-grey-800,
    primary: $color-primary-900,
  );
  @each $key, $value in $colors {
    .tip-text-#{$key} {
      position: relative;
      margin: 0;
      div,
      p {
        margin: 1rem 0;
        min-height: 1.8rem;
      }
    }
    .tip-text-#{$key}::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 3px;
      width: 2px;
      height: 100%;
      background-color: $value;
    }
  }
}
