.cdk-overlay-dark-backdrop {
  background: rgba($color-black, 0.32);
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: $color-grey-900 !important;
}

mat-dialog-container {
  .mat-mdc-dialog-surface:has(.panel-dialog:not(.no-margin-panel)) {
    padding-top: 1.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2.5rem;
  }

  .custom-dialog-container {
    mat-dialog-container.mat-mdc-dialog-container {
      padding: 0;
    }
  }

  .mat-mdc-dialog-title {
    padding-top: 9px;

    &:not(:has(.tabs)) {
      height: 50px;
    }

    &::before {
      height: auto;
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-grey-200;
    }

    .tabs {
      padding: 0;
      background-color: transparent;
      border: none;
    }
  }

  .mat-mdc-dialog-actions {
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 3rem;

    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-grey-200;
    }
  }

  .mat-mdc-dialog-content {
    &.fit-content {
      margin: -2rem -5rem -1.5rem -5rem;
      max-height: none;
    }

    &.fit-lib-search {
      max-height: none;
      margin-bottom: -1.5rem;
      padding: 0;

      lib-search,
      lib-metrics-library-doc-search,
      lib-data-requests-doc-search {
        .panel-search.layout-grid {
          .collection-items .grid-end {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }
      }

      lib-search,
      lib-metrics-library-doc-search {
        height: 65vh;
      }

      lib-data-requests-doc-search {
        height: 50vh;
      }
    }

    .mat-mdc-dialog-content-container {
      max-height: 65vh;

      .content {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .grid.grid-2 {
          grid-template-columns: auto 1fr;
          grid-gap: 1.5rem;
          grid-auto-rows: auto;
        }
      }

      .mat-tree {
        min-height: 4rem;
      }
    }
  }

  .mat-typography {
    .mat-mdc-dialog-title {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 0 3rem;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: -3rem;
        right: -3rem;
        height: 1px;
        background-color: $color-grey-200;
      }

      &.error-header {
        color: $color-error;
      }

      .tabs {
        display: flex;
        flex: 1 1 100%;
        padding: 0;
        border: 0;
        background-color: transparent;

        a {
          padding-top: 0.6rem;
        }
      }
    }

    .mat-mdc-dialog-actions {
      margin-top: 1.5rem;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 3rem;

      &.space-between {
        justify-content: space-between;
      }

      &.warning {
        justify-content: space-between;

        mat-icon {
          color: $color-warning;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: -3rem;
        right: -3rem;
        height: 1px;
        background-color: $color-grey-200;
      }

      > a {
        margin-right: 1rem;
      }
    }
  }
}
